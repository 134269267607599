<template>
  <div>
    <div class="text-center display-1 font-weight-normal mb-6">
      Vamos começar preenchendo as informações básicas para o login
    </div>
    <v-row class="mx-auto">
      <v-col cols="12">
        <validation-provider
          v-slot="{ errors }"
          rules="required"
        >
          <v-text-field
            id="usuario-nome"
            label="Nome do usuário"
            prepend-icon="mdi-card-account-details"
            v-model="userInfoLocal.nome"
            :error-messages="errors"
          />
        </validation-provider>
      </v-col>
    </v-row>
    <v-row class="mx-auto">
      <v-col cols="12">
        <validation-provider
          v-slot="{ errors }"
          name="E-mail"
          rules="required|email"
        >
          <v-text-field
            id="usuario-email"
            label="E-mail"
            prepend-icon="mdi-email"
            v-model="userInfoLocal.email"
            :error-messages="errors"
          />
        </validation-provider>
      </v-col>
    </v-row>
    <v-row class="mx-auto">
      <v-col cols="12">
        <validation-provider
          v-slot="{ errors }"
          rules="required"
        >
          <v-text-field
            id="usuario-login"
            label="Login"
            prepend-icon="mdi-account"
            v-model="userInfoLocal.login"
            :error-messages="errors"
          />
        </validation-provider>
      </v-col>
    </v-row>
    <v-row class="mx-auto">
      <v-col cols="12">
        <v-text-field
          v-model="userInfoLocal.senha"
          :type="mostrarSenha ? 'text' : 'password'"
          label="Senha"
          prepend-icon="mdi-lock-outline"
          :append-icon="mostrarSenha ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="mostrarSenha = !mostrarSenha"
          validate-on-blur
        />
      </v-col>
    </v-row>
    <div class="text-center display-1 font-weight-normal mb-6">
      Opcionalmente, preencha os demais dados do usuário
    </div>
    <v-row class="mx-auto">
      <v-col cols="12">
        <v-select
          id="usuario-tipo"
          v-model="userInfoLocal.tipo"
          name="tipo"
          label="Tipo do usuário"
          :items="['COLABORADOR', 'TERCEIRO']"
          class="mb-0 pb-0"
          prepend-icon="mdi-tag"
        />
      </v-col>
    </v-row>
    <v-row class="mx-auto">
      <v-col cols="12">
        <v-text-field
          id="usuario-cargo"
          label="Cargo"
          prepend-icon="mdi-certificate"
          v-model="userInfoLocal.cargo"
        />
      </v-col>
    </v-row>
    <v-row class="mx-auto">
      <v-col cols="12">
        <v-text-field
          id="usuario-diretoria"
          label="Diretoria"
          prepend-icon="mdi-account-tie"
          v-model="userInfoLocal.diretoria"
        />
      </v-col>
    </v-row>
    <v-row class="mx-auto">
      <v-col cols="12">
        <v-text-field
          id="usuario-area-executiva"
          label="Área Executiva"
          prepend-icon="mdi-tie"
          v-model="userInfoLocal.areaExecutiva"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'FirstTabContent',

  props: {
    userInfo: {
      type: Object,
      default: () => ({
        nome: '',
        login: '',
        senha: '',
        email: '',
        tipo: 'COLABORADOR',
        cargo: 'CARGO PADRÃO',
        diretoria: 'DIRETORIA PADRÃO',
        areaExecutiva: 'ÁREA EXECUTIVA PADRÃO'
      })
    }
  },

  data() {
    return {
      mostrarSenha: false
    };
  },

  computed: {
    userInfoLocal: {
      get() {
        return this.userInfo;
      },
      set(newValue) {
        this.$emit('update:userInfo', newValue);
      }
    }
  }
};
</script>
